<template>
  <section id="video-form">
    <div class="row mt-3">
      <div class="col-12">
        <h3 class="section-head-title ps-0 mb-3">
          Completa los siguientes campos para {{ editing ? 'editar' : 'publicar'  }}  un video
        </h3>

        <div class="card card-custom border-round-10 card-shadow border-0 mb-3">
          <div class="card-body d-flex flex-column mh-700">
            <div class="row flex-grow-1 mt-3">
              <AddSection1 :sec_uno.sync="sec_uno" />
              <AddSection2 :sec_dos.sync="sec_dos" :editing="editing" />
              <AddSection3 :sec_tres.sync="sec_tres" :editing="editing" />
            </div>

            <div class="row">
              <div class="col-12">
                <div
                  class="d-flex align-items-center justify-content-end pt-2 mt-4 border-top border-2"
                >
                  <button
                    class="btn btn-custom-color-white border border-round-50 mw-100 me-2"
                    @click="openQuestion()"
                  >
                    Volver
                  </button>
                  <span
                    :content="disabledButtonText"
                    v-tippy="{
                      placement: 'top',
                      arrow: true,
                      trigger: disabledButton ? 'mouseenter focus' : '',
                    }"
                  >
                    <button
                      class="btn btn-custom-color-blue border-round-50 mw-100"
                      @click="editing ? editVideo() : addVideo()"
                      :disabled="disabledButton"
                    >
                    {{ editing ? 'Guardar' : 'Publicar'  }}
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Status
      v-if="open_modal_status"
      :msg="modal_status_msg"
      :status="modal_status"
      @close="closeStatus"
    />
    <Question
      v-if="open_question_modal"
      :msg="!editing ? question_modal_msg.add : question_modal_msg.edit"
      :hideCancel="false"
      @cancel="cancelQuestion"
      @accept="acceptQuestion"
    />
    <Spinner v-if="show_spinner"></Spinner>
  </section>
</template>

<script>
import AddSection1 from "./AddSection1.vue";
import AddSection2 from "./AddSection2.vue";
import AddSection3 from "./AddSection3.vue";
import Status from "../Modales/Status.vue";
import Spinner from "../Spinner.vue";
import { mapActions } from "vuex";
import Question from "../Modales/Question.vue";

export default {
  components: {
    AddSection1,
    AddSection2,
    AddSection3,
    Status,
    Spinner,
    Question,
  },
  data() {
    return {
      id_usuario: this.$ls.get("user").id_usuario,
      id_empresa: this.$ls.get("user").empresa[0].id_empresa,
      open_modal_status: false,
      modal_status_msg: "",
      open_question_modal: false,
      question_modal_msg: {
        edit: "¿Estas seguro que deseas cancelar la edición? Se perderán los cambios realizados.",
        add: "¿Estas seguro que deseas cancelar la subida del video? Se perderá la información ingresada.",
      },
      show_spinner: false,
      modal_status: true,
      editing: false,
      isPristine: true,
      sec_uno: {
        title: "",
        categoria: {},
        descripcion: "",
      },
      sec_dos: {
        img: "",
        video: "",
      },
      sec_tres: {
        date: "",
      },
      initialValues: {},
    };
  },

  watch: {
    sec_uno: {
      deep: true,
      handler(newVal) {
        if (!this.areEqual(newVal, this.initialValues.sec_uno))
          this.isPristine = false;
      },
    },
    sec_dos: {
      deep: true,
      handler(newVal) {
        if (!this.areEqual(newVal, this.initialValues.sec_dos))
          this.isPristine = false;
      },
    },
    sec_tres: {
      deep: true,
      handler(newVal) {
        if (!this.areEqual(newVal, this.initialValues.sec_tres))
          this.isPristine = false;
      },
    },
  },

  async created() {
    if (this.$route.params.id) {
      this.editing = true;
      await this.getVideo(this.$route.params.id);
    }
  },
  computed: {
    disabledButton() {
      const { title, descripcion, categoria } = this.sec_uno;
      const { img, video } = this.sec_dos;
      const { date } = this.sec_tres;
      if (
        title.trim() == null || title.trim() == "" ||
        descripcion.trim() == null || descripcion.trim() == "" ||
        categoria?.id_categoria == undefined
      )
        return true;
      if (img == "" || video == "" ||img == null || video == null) return true;
      if (date == "") return true;

      return false;
    },
    disabledButtonText() {
      const { title, descripcion, categoria } = this.sec_uno;
      const { img, video } = this.sec_dos;
      const { date } = this.sec_tres;
      const text = [];
      if (
        title.trim() == null || title.trim() == "" ||
        descripcion.trim() == null || descripcion.trim() == "" ||
        categoria?.id_categoria == undefined
      )
        text.push("Todos datos de video");
      if (img == "" || video == "" ||img == null || video == null)
        text.push("Todos los datos de archivo");
      if (date == "") text.push("El dato de fecha");

      return text.length == 0 ? "" : `Debes completar: <br> ${text.join("<br>")}`;
    },
  },

  updated() {
    if (this.$route.params.id) {
      this.editing = true;
    }
  },

  methods: {
    ...mapActions("videoModule", [
      "addVideoAction",
      "editVideoAction",
      "getVideoAction",
    ]),

    closeStatus() {
      this.open_modal_status = false;
      if (this.modal_status) {
        this.$router.push({
          name: "Videos",
        });
      }
    },

    getPayload() {
      const requiredFields = [
        "title",
        "descripcion",
        "categoria",
        "video",
        "img",
        "date",
      ];
      const missingFields = requiredFields.filter(
        (field) =>
          !this.sec_uno[field] && !this.sec_dos[field] && !this.sec_tres[field]
      );
      if (missingFields.length > 0) {
        return { err: true };
      }

      const imagen =
        this.sec_dos.img.split(":")[0] == "data"
          ? this.sec_dos.img.split(",")[1]
          : this.sec_dos.img;
      const formatoImagen =
        this.sec_dos.img.split(":")[0] == "data"
          ? this.sec_dos.img.split(":")[1].split(";")[0]
          : "image/" +
            this.sec_dos.img.split(".")[this.sec_dos.img.split(".").length - 1];

      const video =
        this.sec_dos.video.split(":")[0] == "data"
          ? this.sec_dos.video.split(",")[1]
          : this.sec_dos.video;
      const formatoVideo =
        this.sec_dos.video.split(":")[0] == "data"
          ? this.sec_dos.video.split(":")[1].split(";")[0]
          : "video/" +
            this.sec_dos.video.split(".")[
              this.sec_dos.video.split(".").length - 1
            ];

      const data = {
        tituloVideo: this.sec_uno.title,
        descripcionVideo: this.sec_uno.descripcion,
        idCategoria: this.sec_uno.categoria.id_categoria,
        video: video,
        formatoVideo: formatoVideo,
        imagen: imagen,
        formatoImagen: formatoImagen,
        fechaVideo: this.sec_tres.date,
        idEmpresa: this.id_empresa,
        idUsuario: this.id_usuario,
        tipoSegementacion: "0",
        segmentaciones: "0",
        alertaPush: "0",
      };

      if (this.editing) {
        Object.assign(data, { idVideo: this.$route.params.id });
      }

      return { ...data, err: false };
    },

    async uploadVideoWithDelayAwareness(uploadData) {
      let elapsedSeconds = 0;
      const startUploadingTime = setInterval(() => {
        elapsedSeconds++;
        if (elapsedSeconds === 15) {
          alert(
            "La carga del video está tomando mucho tiempo. Considera usar un compresor online seguro como https://tsubasa.js.org/"
          );
        }
      }, 1000);
      const uploadedFileObj = await this.addVideoAction(uploadData);
      clearInterval(startUploadingTime);
      return uploadedFileObj;
    },

    async verifyVideoUploadStatus(url) {
      try {
        const res = await fetch(url, { method: "HEAD" });
        const status = res.status;
        if (status !== 200) {
          this.toastVideoURLError();
        }
      } catch (error) {
        this.toastConnectionError();
        console.error("Error:", error);
      }
    },

    async addVideo() {
      this.show_spinner = true;
      const payload = this.getPayload();
      if (payload.err) {
        this.toastAllFieldsValidation();
      } else {
        const uploadedFileObj = await this.uploadVideoWithDelayAwareness(
          payload
        );
        if (!uploadedFileObj) {
          this.toastVideoUploadError();
        } else {
          await this.verifyVideoUploadStatus(uploadedFileObj.url_video);
          this.toastVideoUploadSuccess();
          this.closeStatus();
        }
      }
      this.show_spinner = false;
    },

    async editVideo() {
      this.show_spinner = true;
      const payload = this.getPayload();
      if (payload.err) {
        this.toastAllFieldsValidation();
      } else {
        const res = await this.editVideoAction(payload);
        if (!res) {
          this.toastVideoEditError();
        } else {
          this.toastVideoEditSuccess();
          this.closeStatus();
        }
      }
      this.show_spinner = false;
    },

    async getVideo(id) {
      this.show_spinner = true;
      const payload = {
        idEmpresa: this.id_empresa,
        idVideo: [`${id}`],
      };
      const res = await this.getVideoAction(payload);
      console.log("res", res);

      if (!res) {
        this.toastVideoGetError();
      } else {
        this.setSectionsData(res);
      }
      this.show_spinner = false;
    },

    setSectionsData(video) {
      this.sec_uno = {
        title: video.titulo_video,
        categoria: video.categoria[0],
        descripcion: video.descripcion_video,
      };
      this.sec_dos = {
        img: video.url_imagen,
        video: video.url_video,
      };
      this.sec_tres = {
        date: video.fecha_video,
      };

      // eslint-disable-next-line no-undef
      this.initialValues.sec_uno = structuredClone(this.sec_uno);
      // eslint-disable-next-line no-undef
      this.initialValues.sec_dos = structuredClone(this.sec_dos);
      // eslint-disable-next-line no-undef
      this.initialValues.sec_tres = structuredClone(this.sec_tres);
    },
    openQuestion() {
      this.open_question_modal = true;
    },
    goback() {
      this.$router.push({ name: "videos-list" });
    },
    cancelQuestion() {
      this.open_question_modal = false;
    },
    acceptQuestion() {
      this.goback();
    },

    toastAllFieldsValidation() {
      this.$toast.open({
        message:
          "Problemas al subir el video. Por favor completa todos los campos.",
        type: "error",
        duration: 5000,
        position: "top-right",
      });
    },
    toastVideoUploadSuccess() {
      this.$toast.open({
        message: "Video creado con éxito.",
        type: "success",
        duration: 5000,
        position: "top-right",
      });
    },
    toastVideoUploadError() {
      this.$toast.open({
        message:
          "El video no se ha podido cargar correctamente. Por favor vuelve a intentarlo.",
        type: "error",
        duration: 5000,
        position: "top-right",
      });
    },
    toastVideoEditSuccess() {
      this.$toast.open({
        message: "Video editado con éxito.",
        type: "success",
        duration: 5000,
        position: "top-right",
      });
    },
    toastVideoEditError() {
      this.$toast.open({
        message:
          "El video no se ha podido editar. Por favor inténtalo nuevamente.",
        type: "error",
        duration: 5000,
        position: "top-right",
      });
    },
    toastVideoGetError() {
      this.$toast.open({
        message:
          "El video no se ha podido obtener. Por favor inténtalo nuevamente.",
        type: "error",
        duration: 5000,
        position: "top-right",
      });
    },
    toastVideoURLError() {
      this.$toast.open({
        message:
          "El video se ha cargado con problemas. Por favor revisa el video cargado o intenta nuevamente.",
        type: "error",
        duration: 6000,
        position: "top-right",
      });
    },
    toastConnectionError() {
      this.$toast.open({
        message:
          "Error en la conexión. Por favor revisa tu conexión a internet.",
        type: "error",
        duration: 6000,
        position: "top-right",
      });
    },
  },
};
</script>
