<template>
  <div class="col-12 col-xl-4 d-flex flex-column mt-3 mt-lg-0">
    <h5 class="font-main text-secondary">Datos del video</h5>
    <div class="card flex-grow-1">
      <div class="card-body">

        <div class="row mb-3">
          <div class="col-12">
            <label class="text-secondary">Añade un titulo al video *</label>
            <input 
              type="text" 
              class="form-control input-custom" 
              maxlength="75"
              v-model="sec_uno.title"
            />
            <div class="form-text text-end">
              {{sec_uno.title.length}}/75
            </div>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-12">
            <label class="text-secondary">Seleciona una categoría *</label>
            <v-select 
              class="selvue-custom"
              :options="video_categories" 
              v-model="sec_uno.categoria"
              label="nombre_categoria"
              :clearable="false"
              placeholder="Seleccionar">
            </v-select>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <label class="text-secondary">Descripción del video *</label>
            <textarea 
              class="form-control textarea-custom" 
              maxlength="500"
              v-model="sec_uno.descripcion"
              rows="3"
            >
            </textarea>
            <div class="form-text text-end">
              {{sec_uno.descripcion.length}}/500
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  props: {
    sec_uno: {
      type: Object,
    },
  },

  data() {
    return {
      id_usuario: this.$ls.get("user").id_usuario,
      id_empresa: this.$ls.get("user").empresa[0].id_empresa,
    };
  },

  computed: {
    ...mapState("videoCatModule", ["video_categories"])
  },

  mounted() {
    this.getCategories();
  },

  methods: {
    ...mapActions("videoCatModule", ["getVideoCategoriesAction"]),

    async getCategories() {
      this.show_spinner = true;
      const payload = {
        idEmpresa: this.id_empresa,
        plataforma: "2",
      };
      const resp = await this.getVideoCategoriesAction(payload);
      if (!resp) {        
        this.toastGenericError();
      }
      this.show_spinner = false;
    },

    toastGenericError() {
      this.$toast.open({
        message: "Error obteniendo información. Por favor recarga la página e intenta nuevamente.",
        type: "error",
        duration: 5000,
        position: "top-right",
      });
    }
  },

};
</script>